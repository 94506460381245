import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

class ContactPage extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!event.target.checkValidity()) {
            this.setState({
                message: "One or more fields have an error. Please check and try again.",
                displayErrors: true,
            });
            return;
        }

        const data = new FormData(event.target);

        let jsonData = {};
        for (const [key, value] of data.entries()) {
            jsonData[key] = value;
        }

        fetch(process.env.GATSBY_SERVICE_URL + 'contact', {
            method: 'POST',
            body: JSON.stringify(jsonData),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            let message = "Thanks for your message! It has been sent successfully.";
            if (!response.ok) {
                message = "There was an error trying to send your message. Please try again later.";
            }

            this.setState({ message, displayErrors: false });
        });
    }

    render() {
        const { message, displayErrors } = this.state;
        return (
            <Layout>
                <Helmet title="Contact Us | Fluxible" />
                <h1 className="page-header">Contact us</h1>
                <form
                    onSubmit={this.handleSubmit}
                    noValidate
                    className={displayErrors ? 'displayErrors pre-footer-padding' : 'pre-footer-padding'}
                >
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x">
                            <div className="cell medium-6 medium-offset-3">
                                <label>Your name (required)
                                <input type="text" name="name" required />
                                </label>

                                <label>Your email (required)
                                <input type="email" name="email" required />
                                </label>

                                <label>Your message (required)
                                <textarea name="message" rows="10" required />
                                </label>

                                <button type="submit" className="button action-button">Send</button>

                                {message && (<div className="callout warning"><p>{message}</p></div>)}
                            </div>
                        </div>
                    </div>
                </form>
            </Layout>
        )
    }
}

export default ContactPage
